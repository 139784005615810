<template>
  <div>
    <h1>About Us</h1>
    <p>
      At myforexglobal.com we want to provide everyone with a reliable source of
      trading education for the forex markets. I am a professional currency
      trader with more focus on price action trading, my ultimate goal is to
      share my most working stress free strategies with everyone.
    </p>
    <p>
      With over 10 years of combined trading market experience you can expect
      practical advice with realistic expectations. Here myforexglobal.com, you
      will find a no nonsense and no hype-approach to any trading market.
    </p>
  </div>
</template>

<script>
export default {};
</script>